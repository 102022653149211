import {Link} from "#/src/components/Link";
import {BrandLogo} from "#/src/components/BrandLogo";

interface Props {
  reason: any
}

export function ErrorContent({reason}: Props) {
  return <section className="register-wrapper pt-4">
    <div className="container p-0 h-100">
      <div className="row g-0 h-100">
        <div className="col-xl-12">
          <div className="login-brand text-center">
            <Link href="/">
              <BrandLogo/>
            </Link>
          </div>
        </div>
        <div className="col-xl-5 mx-auto my-auto">
          <div className="panel mt-4">
            <div className="panel-title">
              <h2 className="basic-headline mb-2">
                Błąd serwera
              </h2>
              <p>{reason}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
}