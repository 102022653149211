import {ErrorContent} from "#/src/pages/_error/ErrorContent";

export {Page}

import {usePageContext} from 'vike-react/usePageContext'

function Page() {
  const pageContext = usePageContext()
  let {abortReason} = pageContext
  if (!abortReason) {
    abortReason = pageContext.is404 ? 'Nie znaleziono takiej strony.' : 'Nieznany błąd serwera.'
  }
  return <div className={"h-100"}>
    <ErrorContent reason={abortReason}/>
  </div>
}
